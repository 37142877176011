import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Pisces.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Pisces 
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/pisces"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Pisces  </h4>
              </a>
              
             
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Pisces </h1>
                <h2 className="text-sm md:text-base ml-4">Feb 19 - Mar 20</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Pisces Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Pisces Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Pisces Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-love");
                  }}>
                  <h5 className="font-semibold text-black">Pisces Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-nature");
                  }}>
                  <h5 className="font-semibold text-black">Pisces Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-man");
                  }}>
                  <h5 className="font-semibold text-black">Pisces Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Pisces Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-traits");
                  }}>
                  <h5 className="font-semibold text-black">Pisces Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/pisces/pisces-facts");
                  }}>
                  <h5 className="font-semibold text-black">Pisces Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
                  <p className="playfair text-black text-xl md:text-3xl">About Pisces</p><br/>
                  <p className="font-bold text-orange-500 inline-block mr-2">Sanskrit/Vedic name:</p>Meena<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Cancer Meaning:</p>The Fish<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Type:</p>Water-Mutable-Negative<br/>
                  <br></br>
                  Among all the zodiac signs, Pisces is the 12th and final zodiac sign. Pisces are known to inherit traits of all 11 other signs in some way. Dreamy romantic signs are known for their charming and creative side. For some, it looks like free-flowing poetry, for others it resembles the blossoming of fresh flowers. Many people find generous and caring Pisces to be selfless, while others (born Pisces) find them a bit strict or strict. <br/>
                  <br></br>
                  When it comes to Pisces, like the two fish symbols swimming in opposite directions, there are many contradictions. Pisces is ruled by Neptune and lives in its world. They are distant, and spiritual, and tend to be very focused on their inner journeys aimed at finding peace and harmony. , hates confrontation. They prefer to take the roundabout instead and will do anything to avoid a collision. They are humble and quiet, love to be around, and develop a sense of security and comfort. These natives love cozy nests and make great keepers and parents too. <br/>
                  <br></br>
                  Caring, loving, and loyal, Pisces often love treats unless they show negative signs (of personality) or fall irrevocably in love. For Fish, love is all about giving than receiving. Though tolerant, respectful, and tolerant, Pisces can be shy in their way, and as a result can be abused, taken for granted, or betrayed. Yes, fish are also difficult to change and be rather lazy and passive. In highly negative behavior, Pisces is prone to complete inactivity, hedonism, vindictiveness, and/or multiple relationships.<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Ruling planets:</p>Jupiter and Neptune<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Element:</p>Water<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Quality:</p>Mutable (= flexibility)<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Symbol:</p>A pair of Fishes, swimming in opposing directions<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">The Symbol denotes:</p>Mood swings, Emotional nature, and lack of clarity of mind<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Characteristics:</p>Intuitive, feminine, watery, mutable, emotional.<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Noteworthy qualities:</p>Inspirational, intuitive, methodical, hospitable, peace-loving, compassionate, sympathetic, imaginative, kind, creative, sensitive.<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Undesirable personality traits:</p>Pessimistic, indecisive, lethargic, careless, submissive, over-sensitive, self-depreciation, timid, self-pity, moody, negative.<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Birthstone:</p>Yellow Topaz, Yellow Sapphire, (The gemstone is suggested considering Aries as the Ascendant/Lagna Sign<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Birth Colours:</p>Light Yellow, Aqua, Blue-green,<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Other lucky colors:</p>Yellow-orange, Earth tones, Yellow, Red, Scarlet<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Lucky Numbers are:</p>3, 7, 12, 16, 21, 25, 30, 34, 43, 52<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Compatible Signs:</p>Cancer, Scorpio<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Lucky Day:</p>Thursday<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Deepest need:</p>Spiritual illumination, Liberation<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Quality most needed for balance:</p>Structure, Ability to manage form, Practicality<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Detriment:</p>Mercury<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Exaltation:</p>Venus, Neptune’s octave<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Fall:</p>Mercury<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Associated Flowers and Plants:</p>Moss, Seaweed, Other aquatic plants<br/>
                  <br></br>
                  <p className="font-bold text-orange-500 inline-block mr-2">Best Sign/s for marriage and/or partnerships:</p>Virgo<br/>
                  <br></br>
                  Anatomically Pisces compares to Feet and toes; the lymphatic system, the duodenum, all the extremities in general, and the cecum; bones in the feet and toes; arteries in the feet and the extremities; muscles governing the movement of the feet and toes; veins in the feet and the extremities.<br/>
                  <br></br>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
